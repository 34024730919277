import React from 'react'
import styled from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'


const Icon: React.FC<SvgProps> = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_35_1048)">
                <path d="M19.6157 14.152C18.7532 13.341 17.1393 12.1211 17.1393 8.125C17.1393 5.08984 14.7072 2.66016 11.4277 2.06406V1.25C11.4277 0.559766 10.7884 0 10 0C9.21164 0 8.57235 0.559766 8.57235 1.25V2.06406C5.29289 2.66016 2.86075 5.08984 2.86075 8.125C2.86075 12.1211 1.24691 13.341 0.384406 14.152C0.116549 14.4039 -0.00220128 14.7051 3.08655e-05 15C0.00494158 15.6406 0.579495 16.25 1.43307 16.25H18.567C19.4206 16.25 19.9956 15.6406 20 15C20.0023 14.7051 19.8835 14.4035 19.6157 14.152ZM3.01476 14.375C3.96208 13.2824 4.9978 11.4715 5.00271 8.14766C5.00271 8.13984 5.00003 8.13281 5.00003 8.125C5.00003 5.70859 7.23842 3.75 10 3.75C12.7616 3.75 15 5.70859 15 8.125C15 8.13281 14.9974 8.13984 14.9974 8.14766C15.0023 11.4719 16.038 13.2828 16.9853 14.375H3.01476ZM10 20C11.5768 20 12.8558 18.8809 12.8558 17.5H7.14423C7.14423 18.8809 8.42325 20 10 20Z" fill="#FABA23" />
            </g>
            <defs>
                <clipPath id="clip0_35_1048">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Icon

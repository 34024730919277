import React from 'react'
import styled from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const CsSvg = styled(Svg)`
  width: 25px;
`

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <svg width="20" height="20" {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.235375 31.5383L1.32102 0.60836L31.1769 30.8571L0.235375 31.5383Z" fill="#014235" />
        </svg>

    )
}

export default Icon

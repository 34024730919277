import React from 'react'
import styled from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'


const Icon: React.FC<SvgProps> = (props) => {
    return (
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8674 2.18156e-06L1.13415 2.18156e-06C0.239429 2.18156e-06 -0.3042 0.943302 0.182799 1.66975L6.04945 10.4956C6.15159 10.6501 6.2929 10.7774 6.46022 10.8656C6.62754 10.9538 6.81545 11 7.00646 11C7.19746 11 7.38537 10.9538 7.55269 10.8656C7.72002 10.7774 7.86132 10.6501 7.96347 10.4956L13.8188 1.66975C13.9293 1.50621 13.9916 1.31715 13.9992 1.12253C14.0068 0.927896 13.9594 0.73491 13.862 0.563926C13.7646 0.392942 13.6208 0.2503 13.4457 0.151047C13.2707 0.0517945 13.0709 -0.00038885 12.8674 2.18156e-06Z" fill="#02111B" />
        </svg>

    )
}

export default Icon
